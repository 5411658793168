<template>
  <div id="servebox">
    <CSTabBar
        :checkedTab="checkedTaBar"
        :tabbar="orderState"
        @changeTabBar="changePayStateTabBar"
    ></CSTabBar>
    <!-- 点击订单导航时显示的内容 -->

    <div v-if="listQuery.state == 1">
      <div class="filter-panel">
        <CSSelect style="margin-right: 10px; width: 210px">
          <el-date-picker
              v-model="workOrderQueryParam.startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span
            style="
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 15px 0;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        >
        </span>
        <CSSelect style="margin-left: 10px; width: 210px">
          <el-date-picker
              v-model="workOrderQueryParam.endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>

        <input
            v-model="workOrderQueryParam.search"
            class="cs-input"
            placeholder="搜索用户信息/消费码/商户信息"
            style="width: 240px"
        />
        <CSSelect style="margin-right: 30px">
          <select
              v-model="workOrderQueryParam.state"
              class="form-control typeinput"
              style="padding-left: 5px; color: #999"
          >
            <option value="">订单状态不限</option>
            <option
                v-for="(item, n) in orderType"
                :key="`${item}_${n}`"
                :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </CSSelect>

        <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="orderInquiry()"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel">
              <span>累计已付金额：{{ refundLogs.paymentTotal || "-" }} 元</span>
              <span style="margin-left: 15px"
              >累计抽成收益：{{ refundLogs.rakeTotal || "-" }} 元</span
              >
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">购买时间</th>
              <th class="text-center">用户信息</th>
              <th class="text-center">项目信息</th>
              <th class="text-center">单价</th>
              <th class="text-center">购买数量</th>
              <th class="text-center">已付金额</th>
              <th class="text-center">已退金额</th>
              <th class="text-center">抽成比例</th>
              <th class="text-center">抽成收益</th>
              <th class="text-center">消费码</th>
              <th class="text-center">商户信息</th>
              <th class="text-center">用户评价内容</th>
              <th class="text-center">订单状态</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(log, index1) in refundLogs" :key="index1">
              <td class="text-center">
                {{ log.createDate || "-" }}
              </td>
              <td class="text-center" style="padding-left: 10px;padding-right: 10px;">
                <div>
                  <p>
                    {{ log.userName || "" }} 【{{ log.userPhone || "" }}】
                  </p>
                  <p>{{ log.userCompany || "" }}</p>
                  <p>{{ log.userCompanyAddr || "" }}</p>
                </div>
              </td>
              <td class="text-center">
                <div
                    class="allow-click"
                    @click="lookServiceInfo(log.itemsId)"
                >
                  {{ log.itemsName }}
                </div>
              </td>
              <td class="text-center">
                {{ log.price ? log.price + "元" : "-" }}/人/次
              </td>
              <td class="text-center">
                {{ log.buyNum || "-" }}
              </td>
              <td class="text-center">
                {{ log.paymentMoney ? log.paymentMoney + "元" : "-" }}
              </td>
              <td class="text-center">
                {{ log.refundMoney ? log.refundMoney + "元" : "-" }}
              </td>
              <td class="text-center">{{ log.commissionScale || 0 }}%</td>
              <td class="text-center">
                {{ log.commissionEarnings | getPriceText }}
              </td>
              <td class="text-center">
                {{ log.consumerCode || "-" }}
              </td>
              <td class="text-center">
                <div>
                  <p>
                    {{ log.merchantName || "" }}
                  </p>
                  <p>
                    {{ log.merchantContact || "" }}
                  </p>
                  <p>
                    {{ log.merchantPhone || "" }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                <div
                    v-if="log.evaluateTime"
                    class="allow-click"
                    @click="lookEvaluate(log)"
                >
                  查看
                </div>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                {{ log.state | getOrderTypeStr }}
              </td>
              <td class="text-center">
                <div v-if="log.state !== 3" class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 76px; padding: 0"
                  >
                    <li>
                      <a style="width: 100%; padding: 3px 0"
                         @click="
                                            commitRefundModal.visible = true;
                                            commitRefundModal.data = {
                                            id: log.orderId,
                                            paymentMoney: log.paymentMoney / 1,
                                            paymentOrderNo: log.paymentOrderNo,};"
                      >
                        退款
                      </a>
                    </li>
                  </ul>
                </div>
                <!--                          <button
                                              v-if="log.state !== 3"
                                              @click="
                                        commitRefundModal.visible = true;
                                        commitRefundModal.data = {
                                          id: log.orderId,
                                          paymentMoney: log.paymentMoney / 1,
                                          paymentOrderNo: log.paymentOrderNo,
                                        };
                                      "
                                              class="refunds"
                                          >
                                              退款
                                          </button>-->

                <div v-else>-</div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination/>

      </div>
    </div>

    <!-- 当用户点击到已退款导航时显示的内容 -->
    <div v-if="listQuery.state == 2">
      <div class="filter-panel">
        <CSSelect style="margin-right: 10px; width: 210px">
          <el-date-picker
              v-model="workQueryRefunded.startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span
            style="
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 15px 0;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        >
        </span>
        <CSSelect style="margin-left: 10px; width: 210px">
          <el-date-picker
              v-model="workQueryRefunded.endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="workQueryRefunded.search"
            class="cs-input"
            placeholder="搜索用户信息/消费码/商户信息"
            style="width: 240px; margin-right: 30px"
        />
        <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="orderRefunded"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th class="text-center">退款时间</th>
              <th class="text-center">用户信息</th>
              <th class="text-center">项目信息</th>
              <th class="text-center">单价</th>
              <th class="text-center">购买数量</th>
              <th class="text-center">已付金额</th>
              <th class="text-center">已退金额</th>
              <th class="text-center">抽成比例</th>
              <th class="text-center">抽成收益</th>
              <th class="text-center">消费码</th>
              <th class="text-center">商户信息</th>
              <th class="text-center">用户评价内容</th>
              <th class="text-center">退款原因</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if="refundedLogs.length > 0">
              <tr v-for="(log, index) in refundedLogs" :key="index">
                <td class="text-center">
                  {{ log.refundTime || "-" }}
                </td>
                <td class="text-center" style="padding-right: 10px;padding-left: 10px;">
                  <div>
                    <p>
                      {{ log.userName || "" }} 【{{ log.userPhone || "" }}】
                    </p>
                    <p>{{ log.userCompany || "" }}</p>
                    <p>{{ log.userCompanyAddr || "" }}</p>
                  </div>
                </td>
                <td class="text-center">
                  <div
                      class="allow-click"
                      @click="lookServiceInfo(log.itemsId)"
                  >
                    {{ log.itemsName || "-" }}
                  </div>
                </td>
                <td class="text-center">
                  {{ log.price ? log.price + "元" : "-" }}
                </td>
                <td class="text-center">
                  {{ log.buyNum || "-" }}
                </td>
                <td class="text-center">
                  {{ log.paymentMoney ? log.paymentMoney + "元" : "-" }}
                </td>
                <td class="text-center">
                  {{ log.refundMoney ? log.refundMoney + "元" : "-" }}
                </td>
                <td class="text-center">{{ log.commissionScale }}%</td>
                <td class="text-center">
                  {{ log.commissionEarnings | getPriceText }}
                </td>
                <td class="text-center">
                  {{ log.consumerCode || "-" }}
                </td>
                <td class="text-center">
                  <div>
                    <p>{{ log.merchantName || "" }}</p>
                    <p>
                      {{ log.merchantContact || "" }}【{{
                        log.merchantPhone || ""
                      }}】
                    </p>
                  </div>
                </td>
                <td class="text-center">
                  <div
                      v-if="log.evaluateTime"
                      class="allow-click"
                      @click="lookEvaluate(log)"
                  >
                    查看
                  </div>
                  <template v-else>-</template>
                </td>
                <td class="text-center">
                  <div v-if="log.state === 3">
                    <span class="allow-click" @click="lookRefundDetail(log)"
                    >查看</span
                    >
                  </div>
                  <template v-else>
                    {{ log.state | getOrderTypeStr }}
                  </template>
                </td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </div>

    <!-- 点击项目管理时显示的内容 -->
    <div v-if="listQuery.state == 3">
      <div class="result-panel">
        <CSTable>
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button class="btn btn-primary sticky-right" @click="openAddVisitingService">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加上门服务项目
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th class="text-center">添加时间</th>
              <th class="text-center">项目信息</th>
              <th class="text-center">项目描述</th>
              <th class="text-center">状态</th>
              <th class="text-center">商户信息</th>
              <th class="text-center">抽成比例</th>
              <th class="text-center">剩余库存</th>
              <th class="text-center">用户查看次数</th>
              <th class="text-center">用户购买次数</th>
              <th class="text-center">消费码核销次数</th>
              <th class="text-center">操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if="itemsData.length > 0">
              <tr v-for="(log, index) in itemsData" :key="index">
                <td class="text-center">
                  {{ log.createTime || "-" }}
                </td>
                <td class="text-center" style="padding-right: 10px;padding-left: 10px;">
                  <div
                      class="allow-click"
                      @click="lookServiceInfo(log.itemsId)"
                  >
                    {{ log.itemsName || "-" }}
                  </div>
                </td>
                <td class="text-center">{{ log.depict || "-" }}</td>
                <td class="text-center">
                  {{ log.state ? "已上架" : "已下架" }}
                </td>
                <td class="text-center">
                  <p>{{ log.merchantName || "-" }}</p>
                  <p>
                    {{ log.merchantContact || "" }}【{{
                      log.merchantPhone || ""
                    }}】
                  </p>
                </td>
                <td class="text-center">
                  {{ log.commissionScale ? log.commissionScale + "%" : "-" }}
                </td>
                <td class="text-center">
                  {{ log.stock }}
                </td>
                <td class="text-center">
                  {{ log.userLookCount }}
                </td>
                <td class="text-center">
                  {{ log.userBuyCount }}
                </td>
                <td class="text-center">
                  {{ log.consumeCodeCount }}
                </td>
                <td class="text-center operation">
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li @click="openEditServiceModal(log.itemsId)">
                        <a style="width: 100%">修改项目</a>
                      </li>

                      <li @click="deleteServiceItem(log.itemsId)">
                        <a style="width: 100%">删除项目</a>
                      </li>
                      <li
                          v-if="log.state"
                          @click="noticicationUser(log.itemsId)"
                      >
                        <a style="width: 100%">通知用户</a>
                      </li>
                      <li
                          @click="
                            changeEnableState(log.itemsId, Number(!log.state))
                          "
                      >
                        <a style="width: 100%"
                        >{{ log.state ? "下" : "上" }}架项目</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="text-center" colspan="8">暂无数据</td>
              </tr>
            </template>
          </template>
        </CSTable>
        <Pagination/>
      </div>
    </div>

    <!-- 点击退款按钮时显示的弹出框 -->
    <CSDialog
        :dialogVisible="commitRefundModal.visible"
        dialog-confirm-btn-text="确定"
        dialogTitle="退款"
        dialogWidth="580px"
        @onClose="commitRefundModal.visible = false"
        @onConfirm="commitRefundModal.onOk"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div style="margin-bottom: 23px">
          <span style="margin-right: 40px; vertical-align: middle"
          >已付金额</span
          >
          <span style="vertical-align: middle">{{
              commitRefundModal.data.paymentMoney
            }}</span>
        </div>
        <div>
          <span style="margin-right: 40px; vertical-align: middle"
          >退款金额</span
          >
          <input
              v-model="commitRefundModal.data.paymentMoney"
              disabled
              style="
              vertical-align: middle;
              border: 1px solid #999;
              border-radius: 4px;
              padding-left: 10px;
              background-color: #fff;
            "
              type="text"
          />
          元
        </div>
      </div>
    </CSDialog>

    <CSDialog
        :dialogVisible="commonModal.visible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="commonModal.onCancel"
        @onConfirm="commonModal.onOk"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        {{ commonModal.title }}
      </div>
    </CSDialog>
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="evaluateVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="用户评价"
        dialogWidth="580px"
        @onClose="evaluateVisible = false"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div style="margin-bottom: 23px">
          <label style="width: 100px; text-align: right; margin-right: 40px"
          >评价时间</label
          >
          <span style="width: 380px; display: inline-block">{{
              orderInfo.evaluateTime
            }}</span>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 100px; text-align: right; margin-right: 40px"
          >星级</label
          >
          <span style="width: 380px; display: inline-block">{{
              orderInfo.score
            }}</span>
        </div>
        <div style="margin-bottom: 23px">
          <label
              style="
              width: 100px;
              text-align: right;
              margin-right: 40px;
              vertical-align: top;
            "
          >评价</label
          >
          <span
              style="width: 380px; display: inline-block; vertical-align: top"
          >{{ orderInfo.evaluate || '-' }}</span
          >
        </div>
      </div>
    </CSDialog>
    <div v-show="!previewModalInfo.visible">
      <EditVisitingServiceItem
          :itemId="editModalInfo.data"
          :visible="editModalInfo.visible"
          @closeModal="editModalInfo.onClose"
          @onPreview="editModalInfo.onOk"
      ></EditVisitingServiceItem>
    </div>

    <VisitingServicePreview
        :visible="previewModalInfo.visible"
        :visitingService="previewModalInfo.data"
        @closeModal="previewModalInfo.onClose"
    >
      <template v-slot:btnGroup>
        <div
            v-if="editModalInfo.visible"
            style="text-align: center;padding-top: 10px;"
        >
          <button
              class="btn btn-primary mr-5"
              style="width: 180px; height: 40px"
              @click="previewModalInfo.onClose"
          >
            上一步
          </button>
          <button
              :class="['btn', 'btn-primary', {disabled: isSubmitting}]"
              style="width: 180px; height: 40px"
              @click="commitServiceInfo(previewModalInfo.data)"
          >
            确定
          </button>
        </div>
        <div v-else class="text-center">
          <button
              class="btn"
              style="
              width: 80px;
              height: 40px;
              font-size: 24px;
              background-color: #1ab394;
              color: #fff;
              line-height: 20px;
              box-shadow: 0px 5px 10px 0px rgba(26, 179, 148, 0.3);
            "
              @click="previewModalInfo.onClose"
          >
            关闭
          </button>
        </div>
      </template>
    </VisitingServicePreview>
    <ViewModal></ViewModal>
  </div>
</template>

<script>
import {
  addVisitingServiceItemUrl,
  adminQueryItemsUrl,
  adminQueryOrderUrl,
  adminQueryRefundOrderUrl,
  changeVisitingServiceEnableStateUrl,
  deleteVisitingServiceItemUrl,
  editVisitingServiceItemUrl,
  queryVisitingServiceItemDetailUrl,
  refundVisitingOrderUrl,
  visitingServiceNotificationUrl,
} from "@/requestUrl";

import EditVisitingServiceItem from "@/components/EditVisitingServiceItem";
import VisitingServicePreview from "@/components/phonePreview/VisitingServicePreview";
import ViewModal from "@/components/ViewModal";
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import CSTabBar from "@/components/common/CSTabBar";
import CSDialog from "@/components/common/CSDialog";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";

export default {
  name: "VisitService",
  components: {
    CSTable,
    EditVisitingServiceItem,
    VisitingServicePreview,
    ViewModal,
    Pagination,
    CSSelect,
    CSTabBar,
    CSDialog,
  },
  filters: {
    getOrderTypeStr(val) {
      const orderTypeMapping = {
        4: "未使用",
        2: "已使用",
        3: "已退款",
        5: "支付失败",
      };
      return orderTypeMapping[val] || "-";
    },
    // 获取价格
    getPriceText(val) {
      return val ? val + "元" : "-";
    },
  },
  data() {
    return {
      isSubmitting: false,
      //防抖计时器
      tiemrDown: null,
      filterHeight: 0,
      // 退款操作谈穿
      commitRefundModal: {
        visible: false,
        orderId: null,
        data: {},
        onClose: () => {
          this.commitRefundModal.visible = false;
        },
        onOk: () => {
          this.commitRefundInfo(this.commitRefundModal.data.id);
          this.commitRefundModal.visible = false;
        },
      },
      // 预览弹窗相关信息
      previewModalInfo: {
        visible: false,
        data: {},
        onClose: () => {
          this.previewModalInfo.visible = false;
        },
      },
      // 编辑服务弹窗相关信息
      editModalInfo: {
        visible: false,
        data: null,
        onClose: () => {
          this.editModalInfo.visible = false;
        },
        onOk: () => {
        },
      },
      // 上下架/删除弹窗相关信息
      commonModal: {
        title: "",
        visible: false,
        onCancel: () => {
          this.commonModal.visible = false;
        },
        onOk: () => {
        },
      },
      regionId: this.$vc.getCurrentRegion().communityId,
      // 控制退款弹出框的显示与隐藏
      refundBox: false,
      listQuery: {
        state: 1,
        userInfo: "",
        handler: "",
      },
      orderState: {
        1: "订单",
        2: "已退款",
        3: "项目管理",
      },
      orderType: [
        {id: 4, name: "未使用"},
        {id: 2, name: "已使用"},
        {id: 3, name: "已退款"},
      ],
      // 订单查询返回的数据
      workOrderQueryParam: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        regionId: "",
        type: "",
        state: "",
        keyword: "",
        search: "",
      },
      // 查询订单返回的数据
      refundLogs: [],
      // 已退款查询返回的数据
      workQueryRefunded: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00", //开始时间 必填
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59", //结束时间   必填
        search: "", //用户信息，消费码，商户信息
        regionId: "", //区域id  必填
      },
      // 已退款查询返回的数据
      refundedLogs: [],
      // 项目管理查询返回的数据
      itemsData: [],
      // tabbar状态
      checkedTaBar: 1,
      evaluateVisible: false,
      orderInfo: {},
      isDisable: true,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted() {
    window.addEventListener("keydown", this.orderInquiryDown);
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageNo = _currentPage;
      switch (this.listQuery.state) {
        case 1:
          this.orderInquiry(_currentPage);
          break;
        case 2:
          this.orderRefunded(_currentPage);
          break;
        case 3:
          this.itemsQuery(_currentPage);
          break;
      }
    });
  },
  updated() {
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
  },
  created() {
    switch (this.listQuery.state) {
      case 1:
        this.orderInquiry();
        break;
      case 2:
        this.orderRefunded();
        break;
      case 3:
        this.itemsQuery();
        break;
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.orderInquiryDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.orderInquiryDown);
  },
  methods: {
    orderInquiryDown(e) {
      if (e.keyCode == 13) {

        if (this.listQuery.state == 1) {
          this.orderInquiry();
        } else if (this.listQuery.state == 2) {
          this.orderRefunded();
        }

      }
    },
    /**
     * 查看退款详情
     * @param {Object} 退款记录
     * */
    lookRefundDetail(log) {
      const messageHtml = `
                <div style="text-align: left;">
                    <div>
                        <label style="margin-right: 40px; width: 100px; text-align: right;">已付金额</label><span>${
          log.paymentMoney
      }元</span>
                    </div>
                    <div>
                        <label style="margin-right: 40px; width: 100px; text-align: right;">退款金额</label><span>${
          log.refundMoney
      }元</span>
                    </div>
                    <div>
                        <label id="refundTitle" style="margin-right: 40px; width: 100px; text-align: right;">退款人</label><span>${
          log.refundName || "-"
      }</span>
                    </div>
                </div>
            `;
      this.$CSDialog.alert({
        title: "退款原因",
        width: "480px",
        messageHtml,
      });
    },
    // 提交退款信息
    commitRefundInfo(orderId) {
      this.$fly
          .post(refundVisitingOrderUrl, {
            regionId: this.$vc.getCurrentRegion().code,
            orderId,
            type: 2,
          })
          .then((res) => {
            if (res.code !== 0) {
              retrun;
            }
            this.$vc.toast("退款成功");
            this.commitRefundModal.onClose();
            this.orderInquiry();
          });
    },
    /**
     * 查看评价
     * @param {Object} orderInfo 订单信息
     *  */
    lookEvaluate(orderInfo) {
      this.evaluateVisible = true;
      this.orderInfo = orderInfo;
    },
    /**
     * 获取上门服务详情
     * @param {Number} itemId 项目ID
     *  */
    async getServiceInfoDetail(itemId) {
      return this.$fly
          .post(queryVisitingServiceItemDetailUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            itemsId: itemId,
          })
          .then((res) => {
            const data = res.data;
            data.price = data.price / 1;
            data.serviceDate = JSON.parse(data.serviceDate);
            return {code: res.code, data};
          });
    },
    /**
     * 查看服务详情
     * @param {Number} itemId 项目ID
     *  */
    async lookServiceInfo(itemId) {
      const {data: serviceInfo, code} = await this.getServiceInfoDetail(
          itemId
      );
      if (code != 0) {
        this.$vc.message("获取服务详情失败");
        retrun;
      }
      this.previewModalInfo.visible = true;
      this.previewModalInfo.data = serviceInfo;
    },
    /**
     * 打开编辑服务弹窗
     * @param {Number} itemId 服务项目id
     *  */
    openEditServiceModal(itemId) {
      this.editModalInfo.visible = true;
      this.editModalInfo.data = itemId;
      this.editModalInfo.onOk = (serviceInfo) => {
        if (
            serviceInfo.serviceDate.some(
                (val) => val.startTime === "" || val.endTime === ""
            )
        ) {
          this.$vc.message("请输入时间段");
          return;
        }
        this.previewModalInfo.visible = true;
        this.previewModalInfo.data = serviceInfo;
      };
    },
    /**
     * 提交上门服务信息
     * @param {Object} data 上门服务信息
     *  */
    async commitServiceInfo(data) {
      if (this.isSubmitting) {
        this.$vc.message('请不要重复提交')
        return;
      }
      const params = {...data};
      params.photos = JSON.stringify(params.photos);
      params.serviceDate = JSON.stringify(params.serviceDate);
      this.isSubmitting = true;
      if (params.itemsId && params.itemsId !== "") {
        await this.editVisitingServiceItem(params);
      } else {
        await this.addVisitingServceItems(params);
      }
      this.isSubmitting = false;
    },
    /**
     * 编辑上门服务
     * @param {Object} data 上门服务信息
     *  */
    editVisitingServiceItem(data) {
      return this.$fly.post(editVisitingServiceItemUrl, data).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("修改成功");
        this.itemsQuery();
        this.editModalInfo.visible = false;
        this.previewModalInfo.visible = false;
        return;
      })
          .catch(err => err);
    },
    /**
     * 添加上门服务
     * @param {Object} data 上门服务信息
     *  */
    addVisitingServceItems(data) {
      return this.$fly.post(addVisitingServiceItemUrl, data).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("添加成功");
        this.itemsQuery();
        this.editModalInfo.visible = false;
        this.previewModalInfo.visible = false;
        return;
      })
          .catch(err => err);
    },
    // 添加上门服务
    openAddVisitingService() {
      this.editModalInfo.visible = true;
      this.editModalInfo.data = null;
      this.editModalInfo.onOk = (serviceInfo) => {
        if (
            serviceInfo.serviceDate.some(
                (val) => val.startTime === "" || val.endTime === ""
            )
        ) {
          this.$vc.message("请输入时间段");
          return;
        }
        this.previewModalInfo.visible = true;
        this.previewModalInfo.data = serviceInfo;
      };
    },
    /**
     * 删除服务项目
     * @param {Number} itemsId 项目id
     *  */
    deleteServiceItem(itemsId) {
      this.commonModal.visible = true;
      this.commonModal.title = `确定删除吗?`;
      this.commonModal.onOk = () => {
        this.$fly
            .post(deleteVisitingServiceItemUrl, {
              regionId: this.regionId,
              itemsId,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.commonModal.visible = false;
              this.itemsQuery();
              this.$vc.toast("删除成功");
            });
      };
    },
    /**
     * 通知用户
     * @param {Number} itemsId 项目id
     *  */
    noticicationUser(itemsId) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定通知用户吗?",
        onConfirm: async () => {
          if (this.isDisable) {
            this.isDisable = false;
            await this.$fly
                .post(visitingServiceNotificationUrl, {
                  regionId: this.regionId,
                  itemsId,
                })
                .then((res) => {
                  if (res.code != 0) {
                    return;
                  }
                  this.$CSDialog.instance.closeDialog();
                  this.$vc.toast("通知成功");
                });
            this.isDisable = true;
          }
        },
      });
    },
    /**
     * 更改上下架状态
     *  @param {Number} itemsId 服务项目id
     *  @param {Number} state 上下架状态 0:下架  1:上架
     *  */
    changeEnableState(itemsId, state) {
      this.commonModal.visible = true;
      this.commonModal.title = `确定${state ? "上" : "下"}架吗?`;
      this.commonModal.onOk = () => {
        this.$fly
            .post(changeVisitingServiceEnableStateUrl, {
              regionId: this.regionId,
              itemsId: itemsId,
              state,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.commonModal.visible = false;
              this.itemsQuery();
              this.$vc.toast("操作成功");
            });
      };
    },
    changePayStateTabBar(state) {
      if (this.listQuery.state != state) {
        this.listQuery.state = state;
        this.checkedTaBar = +state;
        this.listQuery.state = +state;
        const tabBarHanlder = {
          1: this.orderInquiry,
          2: this.orderRefunded,
          3: this.itemsQuery,
        };
        tabBarHanlder[state]();
      }
    },
    // 订单查询
    orderInquiry(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQueryOrderUrl, {
            startDate: this.workOrderQueryParam.startTime, //开始时间 必填
            endDate: this.workOrderQueryParam.endTime, //结束时间   必填
            search: this.workOrderQueryParam.search, //用户信息，消费码，商户信息
            state: this.workOrderQueryParam.state, //订单状态  1.未使用2.已使用3.已退款
            regionId: this.$vc.getCurrentRegion().communityId, //区域id   必填
            pageSize,
            pageNo,
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.refundLogs = res?.data?.datas || [];
            this.refundLogs.paymentTotal = res.data.paymentTotal || 0;
            this.refundLogs.rakeTotal = res.data.rakeTotal || 0;
          });
    },
    // 已退款订单查询
    orderRefunded(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQueryRefundOrderUrl, {
            startDate: this.workQueryRefunded.startTime, //开始时间 必填
            endDate: this.workQueryRefunded.endTime, //结束时间   必填
            search: this.workQueryRefunded.search, //用户信息，消费码，商户信息
            regionId: this.$vc.getCurrentRegion().communityId, //区域id   必填
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || 0,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.refundedLogs = res?.data?.datas || [];
          });
    },

    // 项目管理查询
    itemsQuery(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(adminQueryItemsUrl, {
            regionId: this.$vc.getCurrentRegion().communityId, //区域id   必填
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.itemsData = res?.data?.datas || [];
          });
    },
  },
};
</script>

<style>
.refundBox_ .el-dialog__header {
  padding: 0 !important;
}

.refundBox_ .el-dialog div {
  margin-bottom: 40px;
}

.refundBox_ .el-dialog__body {
  padding: 10px 20 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}

.refundBox_ .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}

.refundBox_ .el-dialog div {
  margin-bottom: 20px;
}

.refundReason_ .el-dialog__header {
  padding: 0 !important;
}

.refundReason_ .el-dialog div {
  margin-bottom: 40px;
}

.refundReason_ .el-dialog__body {
  padding: 10px 20 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}

.refundReason_ .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}

.refundReason_ .el-dialog div {
  margin-bottom: 20px;
}

.operation .dropdown-menu {
  transform: translate3d(-40px, 35px, 0px) !important;
  z-index: 9999 !important;
}

.box_body1 .el-card__body {
  padding: 20px 0 0px 0;
}
</style>

<style scoped>
i {
  font-style: normal;
}

.col-lg-12 > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#servebox .col-lg-12 {
  padding-left: 0;
  margin-bottom: 20px;
}

.nav {
  margin-bottom: 20px;
  padding-left: 30px;
}

.line {
  margin: 0 10px 0 5px;
}

.form-group {
  margin-right: 20px;
}

#refundTitle {
  width: 80px !important;
  text-align: right;
  padding-left: 15px;
}

.btn_close {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  margin-right: 20px;
}

.btn_affirm {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}

.el-dialog input {
  width: 80px;
  padding-left: 5px;
  border: 1px solid #999;
  border-radius: 2px;
}

.el-dialog textarea {
  width: 720px;
  height: 200px;
  border: none;
  background-color: #f0f0f0;
  resize: none;
  border-radius: 6px;
}

.refundBox_ span {
  margin-right: 40px;
}

.refundReason_ div {
  margin-bottom: 20px;
}

.refundReason_ div span {
  margin-right: 20px;
}

.el-dialog input::-webkit-input-placeholder {
  color: #999;
}

/* 项目管理模块样式 */
.addVisitingService {
  position: relative;
  width: 150px;
  height: 30px;
  background-color: #1ab394;
  color: #fff;
  vertical-align: middle;
  padding: 7px 0 15px 25px;
  margin-bottom: 15px;
  border-radius: 6px;
  margin-right: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
}

.el-icon-plus {
  position: absolute;
  top: 7px;
  left: 7px;
  font-weight: 900;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 15px;
}

#servebox .box_body .nav .el-card__body {
  padding: 0px !important;
  padding-bottom: 0;
}

/* .nav .el-card__body {
  padding: 0px !important;
} */
.table-stripped thead {
  border-top: 1px solid #dddddd;
  height: 50px !important;
}

tbody.text-center {
  padding-bottom: 0 !important;
}

.text-center {
  padding: 15px 0;
  vertical-align: middle;
}

.cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
  margin-right: 30px;
}

.determine {
  width: 80px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
  margin-right: 95px;
}

.SecondPrompt {
  font-size: 20px;
  text-align: center;
}

.refunds {
  width: 50px;
  height: 30px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  border: none;
}

td p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.box_body tbody .text-center {
  padding-bottom: 0 !important;
}

.padding_ tbody .text-center {
  padding-bottom: 15px !important;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}

</style>
